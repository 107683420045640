import styled from "styled-components";


export const Container = styled.div`
    padding: 40px 2%;
    background-color: aliceblue;
`;
export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .vantagem_item{
        display: flex;
        align-items: center;
        gap: 10px;

        svg{
            font-size: 60px;
            color: #00bfa5;
        }

        .vantagem_descricao{

            h3{
                color: #444;
            }

            p{
                padding: 5px 0;
                color: #00bfa5;
            }
        }
    }


    @media screen and (max-width: 960px) {
        justify-content: center;
        gap: 30px;

        .vantagem_item {
            width: 33.3%; /* Garante que cada item ocupe a largura total */
            display: flex;
            justify-content: center; /* Centraliza os itens horizontalmente */

            font-size: 14px;

            svg{
                font-size: 60px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        justify-content: center;
        gap: 30px;

        .vantagem_item {
            width: 33.3%; /* Garante que cada item ocupe a largura total */
            display: flex;
            justify-content: center; /* Centraliza os itens horizontalmente */

            font-size: 14px;

            svg{
                font-size: 90px;
            }
        }
    }

`;