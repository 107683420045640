import React, { useState, useEffect } from "react";
import { Container, Content } from "./style";
import { Link } from "react-router-dom";
import img_1 from '../../assets/njr banners/1.png';
import img_2 from '../../assets/njr banners/2.png';
import img_3 from '../../assets/njr banners/3.png';
import img_4 from '../../assets/njr banners/4.png';

const images = [
    img_1, 
    img_2, 
    img_3, 
    img_4,
];

const links = [
    "/produtos",
    "/produtos",
    "/produtos/NBA", 
    "/produtos",
    "/produtos",
];

const HomeCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // 10 segundos
    return () => clearInterval(interval); // Limpeza ao desmontar o componente
  }, []);

  return (
    <Container>
      <Content>
        <div className="carousel_wrapper">
          {images.map((image, index) => (
            <div
              key={index}
              className={`item_single ${index === currentIndex ? "active" : ""}`}
            >
              <Link to={links[currentIndex]}>
                <img src={image} alt={`imagem_carousel_${index + 1}`} />
              </Link>
            </div>
          ))}
        </div>
      </Content>
    </Container>
  );
};

export default HomeCarousel;
