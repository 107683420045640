import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
`;

export const Content = styled.div`
  .carousel_wrapper {
    position: relative;
    width: 100%;
    height: auto; /* Define altura automática para evitar cortes */
    aspect-ratio: 16 / 9; /* Mantém proporção padrão de 16:9 */
    max-height: 780px;
    overflow: hidden;
    background-color: #f0f8ff;

    .item_single {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 1.5s ease-in-out;

      img {
        width: 100%;
        height: auto; /* Ajusta altura dinamicamente */
        object-fit: contain; /* Evita cortes na imagem */
      }
    }

    .item_single.active {
      opacity: 1;
    }
  }

  /* Responsividade */
  @media screen and (max-width: 1441px) {
    .carousel_wrapper {
      height: auto; /* Deixa a altura ajustável conforme a imagem */
      aspect-ratio: 16 / 9;
    }

    .item_single img {
      object-fit: contain; /* Mostra a imagem completa sem cortes */
    }
  }

  @media screen and (max-width: 1024px) {
    .carousel_wrapper {
      height: auto; /* Proporção flexível em telas menores */
    }

    .item_single img {
      object-fit: contain; /* Garante que a imagem não corte detalhes importantes */
    }
  }

  @media screen and (max-width: 768px) {
    .carousel_wrapper {
      height: auto; /* Ajusta altura dinamicamente */
    }

    .item_single img {
      object-fit: contain; /* Prioriza mostrar toda a imagem sem cortes */
    }
  }

  @media screen and (max-width: 480px) {
    .carousel_wrapper {
      height: auto; /* Altura ajustável para dispositivos móveis menores */
    }

    .item_single img {
      object-fit: contain; /* Garante exibição sem cortes */
    }
  }
`;
