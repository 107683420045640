import React from "react";
import { Container, Content } from "./style";
import { FaShippingFast, FaMapMarkedAlt, FaCreditCard } from "react-icons/fa";
import { MdOutlineSecurity } from "react-icons/md";


const HomeAdvantage = () =>{
    return(
        <Container>
            <Content>
                <div className="vantagem_item">
                    <FaShippingFast />
                    <div className="vantagem_descricao">
                        <h3>Frete Grátis</h3>
                        <p>A partir de 3 pedidos</p>
                    </div>
                </div>
                <div className="vantagem_item">
                    <FaMapMarkedAlt />
                    <div className="vantagem_descricao">
                        <h3>Enviamos</h3>
                        <p>Para todo Brasil</p>
                    </div>
                </div>
                <div className="vantagem_item">
                    <FaCreditCard />
                    <div className="vantagem_descricao">
                        <h3>Parcele em 12x</h3>
                        <p>Pague com praticidade</p>
                    </div>
                </div>
                <div className="vantagem_item">
                    <MdOutlineSecurity />
                    <div className="vantagem_descricao">
                        <h3>100% Seguro</h3>
                        <p>Certificado SSL</p>
                    </div>
                </div>
            </Content>
        </Container>
    );
}


export default HomeAdvantage;