// useApi.js
import Axios from 'axios';

export const api = Axios.create({
  baseURL: 'https://njrshop-test-dois.vercel.app/',
});

export const useApi = () => ({
  signin: async (email, password) => {
    try {
      const response = await api.post('/auth/login', { email, password });
      return response.data;
    } catch (error) {
      return { error: error.response?.data?.msg || "Erro ao fazer login" };
    }
  },
  
  editUser: async (nome, email, telefone, rua, bairro, numero, complemento, cidade, estado, cep, cpf) => {
    const user = localStorage.getItem('u');
    const id = JSON.parse(user)?._id;  // Captura o ID do usuário logado a cada chamada
    if (!id) return { error: "ID do usuário não encontrado" };

    try {
      const response = await api.patch(`/auth/editar-perfil/${id}`, { nome, email, telefone, rua, bairro, numero, complemento, cidade, estado, cep, cpf });
      return response.data;
    } catch (error) {
      return { error: error.response?.data?.msg || "Erro ao editar perfil" };
    }
  },
});
