import React, { useState, useContext, useEffect } from "react";
import { Container, Content } from "./style";
import { Link, useNavigate } from "react-router-dom";
import { BtnSecondary } from "../../styles/Buttons";
import { Context } from "../../contexts/authContext";

const Info = () => {
    const { editPerfil, user, msg } = useContext(Context);

    const [values, setValues] = useState({
        nome: '',
        email: '',
        telefone: '',
        cpf: '',
        rua: '',
        bairro: '',
        numero: '',
        complemento: '',
        cidade: '',
        estado: '',
        cep: ''
    });

    const navigate = useNavigate();

    // Preencher o estado inicial com os valores do usuário
    useEffect(() => {
        if (user) {
            setValues({
                nome: user.nome || '',
                email: user.email || '',
                telefone: user.telefone || '',
                cpf: user.cpf || '',
                rua: user.endereco?.rua || '',
                bairro: user.endereco?.bairro || '',
                numero: user.endereco?.numero || '',
                complemento: user.endereco?.complemento || '',
                cidade: user.endereco?.cidade || '',
                estado: user.endereco?.estado || '',
                cep: user.endereco?.cep || ''
            });
        }
    }, [user]);

    const handleChangeValues = (value) => {
        setValues((prev) => ({
            ...prev,
            [value.target.name]: value.target.value,
        }));
    };

    const handleEditPerfil = async (e) => {
        e.preventDefault();
        console.log('Dados enviados:', values);
        const isEdited = await editPerfil(
            values.nome,
            values.email,
            values.telefone,
            values.rua,
            values.bairro,
            values.numero,
            values.complemento,
            values.cidade,
            values.estado,
            values.cep,
            values.cpf
        );
        if (isEdited) {
            navigate('/Perfil');
        }
    };
    

    return (
        <Container>
            <div className="nav_info_title">
                <p>
                    <Link to="/">Início</Link> / <Link to="/perfil">Minha Conta</Link> / Info
                </p>
                <h3>Minha Conta</h3>
            </div>

            <Content>
                <div className="form_editar">
                    <form>
                        <div className="input_style">
                            <p>Nome Completo</p>
                            <input type="text" name="nome" defaultValue={values.nome} onChange={handleChangeValues} />
                        </div>
                        <div className="input_style">
                            <p>Telefone</p>
                            <input type="text" name="telefone" defaultValue={values.telefone} onChange={handleChangeValues} />
                        </div>
                        <div className="input_style">
                            <p>CPF</p>
                            <input type="text" name="cpf" defaultValue={values.cpf} onChange={handleChangeValues} />
                        </div>
                        <div className="input_style">
                            <p>Rua</p>
                            <input type="text" name="rua" defaultValue={values.rua} onChange={handleChangeValues} />
                        </div>
                        <div className="input_style">
                            <p>Bairro</p>
                            <input type="text" name="bairro" defaultValue={values.bairro} onChange={handleChangeValues} />
                        </div>
                        <div className="input_style">
                            <p>Número</p>
                            <input type="text" name="numero" defaultValue={values.numero} onChange={handleChangeValues} />
                        </div>
                        <div className="input_style">
                            <p>Complemento</p>
                            <input type="text" name="complemento" defaultValue={values.complemento} onChange={handleChangeValues} />
                        </div>
                        <div className="input_style">
                            <p>Cidade</p>
                            <input type="text" name="cidade" defaultValue={values.cidade} onChange={handleChangeValues} />
                        </div>
                        <div className="input_style">
                            <p>Estado</p>
                            <input type="text" name="estado" defaultValue={values.estado} onChange={handleChangeValues} />
                        </div>
                        <div className="input_style">
                            <p>CEP</p>
                            <input type="text" name="cep" defaultValue={values.cep} onChange={handleChangeValues} />
                        </div>
                        <div className="btn_editar">
                            <BtnSecondary secondary upper pad onClick={handleEditPerfil}>
                                Salvar alterações
                            </BtnSecondary>
                        </div>
                        {msg && <p className="msg_response">{msg}</p>}
                    </form>
                </div>
            </Content>
        </Container>
    );
};

export default Info;
