import React, { createContext, useEffect, useState } from "react";
import { api, useApi } from "../hooks/useApi";

const Context = createContext();

const AuthProvider = ({ children }) => {
    const auth = useApi();
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [msg, setMsg] = useState([]);
    const [permissions, setPermissions] = useState();

    console.log(user);

    useEffect(() => {
        const recoverUser = localStorage.getItem('u');
        const token = localStorage.getItem('token');
        if (recoverUser && token) {
            setUser(JSON.parse(recoverUser));
            api.defaults.headers.Authorization = `Bearer ${token}`;
        }
        setLoading(false);
    }, []);

    const updateUserInLocalStorage = (updatedUser) => {
        localStorage.setItem('u', JSON.stringify(updatedUser));
        setUser(updatedUser);
    };
    

    const signin = async (email, password) => {
        const data = await auth.signin(email, password);
        if (data.user && data.token) {
            setUser(data.user);
            localStorage.setItem('token', data.token);
            localStorage.setItem('u', JSON.stringify(data.user));
            api.defaults.headers.Authorization = `Bearer ${data.token}`;
            setAuthenticated(true);
            setLoading(false);
            return true;
        }
    };

    const signout = async () => {
        setUser(null);
        localStorage.removeItem('token');
        localStorage.removeItem('u');
        setLoading(false);
    };

    const editPerfil = async (nome, email, telefone, rua, bairro, numero, complemento, cidade, estado, cep, cpf) => {
        const data = await auth.editUser(
            nome || user.nome,
            email || user.email,
            telefone || user.telefone,
            rua || user.endereco?.rua,
            bairro || user.endereco?.bairro,
            numero || user.endereco?.numero,
            complemento || null,
            cidade || user.endereco?.cidade,
            estado || user.endereco?.estado,
            cep || user.endereco?.cep,
            cpf || user.cpf
        ).catch(error => {
            setMsg(error.response?.data?.msg || "Erro ao editar perfil");
            setTimeout(() => setMsg([]), 4000);
        });
    
        if (data) {
            const updatedUser = {
                ...user,
                nome: nome || user.nome,
                email: email || user.email,
                telefone: telefone || user.telefone,
                endereco: {
                    rua: rua || user.endereco?.rua,
                    bairro: bairro || user.endereco?.bairro,
                    numero: numero || user.endereco?.numero,
                    complemento: complemento || null,
                    cidade: cidade || user.endereco?.cidade,
                    estado: estado || user.endereco?.estado,
                    cep: cep || user.endereco?.cep
                },
                cpf: cpf || user.cpf
            };
    
            localStorage.setItem('u', JSON.stringify(updatedUser));
            setUser(updatedUser);
            return true;
        }
    
        return false;
    };
    
    
    

    return (
        <Context.Provider value={{ authenticated: !!user, user, setUser, signin, signout, editPerfil, loading, msg, permissions }}>
            {children}
        </Context.Provider>
    );
};

export { Context, AuthProvider };
