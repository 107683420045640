import React from 'react';
import { Container, Content } from './style';
import { Link } from 'react-router-dom';

const Politic = () =>{
    return(
        <Container>
            <Content>
                <div className='nav_politic__title'>
                    <p><Link to='/'>Início</Link> / Políticas da loja</p>
                    <h2>Políticas da loja</h2>
                </div>
                <div className='politic_container__text'>
                <div className="politic_content__text">

                    <p><strong>Formas de pagamento:</strong></p>

                    <p>- Boleto banc&aacute;rio: ap&oacute;s o pagamento, o prazo para a compensa&ccedil;&atilde;o &eacute; de 3 dias &uacute;teis;</p>

                    <p>- Cart&atilde;o de cr&eacute;dito;</p>

                    <p>- PIX;</p>

                    <p>- Cartão de débito Virtual CAIXA;</p>

                    <p><strong>Envio:</strong></p>

                    <p>- Pedidos de pe&ccedil;as prontas: at&eacute; 3 dias &uacute;teis a partir da data de pagamento (finais de semana e feriados n&atilde;o s&atilde;o contabilizados);</p>

                    <p><b>Aten&ccedil;&atilde;o:</b></p>

                    <p>- Preencha com cuidado todos os dados para a entrega, se houver algum erro no preenchimento ou falta de informa&ccedil;&atilde;o e se a embalagem retornar, n&oacute;s da NJR ESPORTES nos reservamos ao direito de cobrar outro frete.​</p>

                    <p>- Fique atento ao prazo de entrega estipulado pelos Correios no ato da compra.&nbsp;Al&eacute;m do prazo de transporte voc&ecirc; deve considerar o prazo de produ&ccedil;&atilde;o dos itens.</p>

                    <p>- N&atilde;o nos responsabilizamos por atrasos dos Correios causados por greves ou outros motivos;​​</p>

                    <p>- Os Correios realizam at&eacute; 03 tentativas para a realiza&ccedil;&atilde;o da entrega, esgotadas todas elas a encomenda retorna para n&oacute;s e para novo envio ser&aacute; cobrado um novo frete, por isso fique atento ao c&oacute;digo de rastreamento que voc&ecirc; recebe em seu e-mail referente ao seu pedido;​</p>

                    <p>- Se a sua encomenda n&atilde;o chegou dentro do prazo estipulado pelos Correios, envie uma mensagem por direct informando o n&uacute;mero do seu pedido. Mas aten&ccedil;&atilde;o, s&oacute; podemos abrir uma reclama&ccedil;&atilde;o caso tenha esgotado o prazo, n&atilde;o abrimos manifesta&ccedil;&atilde;o por status parado;​</p>

                    <p>- N&atilde;o nos responsabilizamos por extravio, perda, roubo ou atraso.&nbsp;Nos comprometemos em abrir uma reclama&ccedil;&atilde;o nos Correios para que o mesmo se posicione referente ao objeto postado, mas devemos ser informados sobre qualquer problema, a responsabilidade de acompanhar o rastreamento &eacute; do cliente;</p>

                    <p><strong>Formas de envio:</strong></p>

                    <p>- Sedex</p>

                    <p>- PAC</p>

                    <p><strong>Trocas e Devolu&ccedil;&otilde;es:</strong></p>

                    <p>- Troca ou devolu&ccedil;&atilde;o de encomendas: Ap&oacute;s a entrega do produto o cliente tem at&eacute; 7 dias corridos para solicitar a devolu&ccedil;&atilde;o do pedido. O produto deve ser devolvido sem uso e na embalagem original. Ap&oacute;s darem entrada no estoque, o produto passar&aacute; por um processo de avalia&ccedil;&atilde;o que pode levar at&eacute; 3 dias &uacute;teis. Caso seja conclu&iacute;do que n&atilde;o houve uso dos produtos, entraremos com o processo de estorno do valor da compra. Reembolsaremos apenas 40% do valor total da compra: pedido + frete. Todos os custos de frete ser&atilde;o responsabilidade do cliente.</p>

                </div>
                </div>
            </Content>
        </Container>
    );
}

export default Politic