import { datocms } from "../../hooks/datocms";

export const Allprodutos = async (first = 100, skip = 0) => {
    const query = `
        {
            allProdutos(first: ${first}, skip: ${skip}) {
                id
                nome
                categoria
                imagemPrincipal{
                    url(imgixParams: {fm: png, fit: crop, w: 1000, h: 750})
                }
                outrasImagens{
                    url(imgixParams: {fm: png, fit: crop, w: 1000, h: 750})
                }
                isinternational
                valor
                descricao
                tamanhoDoProduto{
                    tamanho
                }
                slug
                _status
                _firstPublishedAt
            }
        }
    `;

    try {
        const response = await datocms.post('', { query });
        
        return response.data.data.allProdutos;
    } catch (error) {
        console.error('Erro ao buscar conteúdo:', error.response ? error.response.data : error.message);
        return [];
    }
};



const removeEmojis = (text) => {
    return text.replace(
        /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F1E0}-\u{1F1FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/gu,
        ""
    );
};

export const generateProductFeedCSV = async () => {
    const query = `
        {
            allProdutos(first: 100) {
                id
                nome
                descricao
                slug
                valor
                categoria
                imagemPrincipal {
                    url
                }
                tamanhoDoProduto {
                    tamanho
                }
                _status
            }
        }
    `;

    try {
        const response = await datocms.post('', { query });
        const produtos = response.data.data.allProdutos;
        console.log("Resposta da API:", response.data);
        // Transformar os produtos em formato de CSV
        const csvRows = [];
        const headers = [
            "id",
            "title",
            "description",
            "link",
            "image_link",
            "price",
            "availability",
            "brand",
            "condition",
            "google_product_category",
            "color",
            "size"
        ];
        csvRows.push(headers.join(',')); // Cabeçalhos

        produtos.forEach((produto) => {
            const description = removeEmojis(produto.descricao || ""); // Remove emojis da descrição
            const color = produto.corDoProduto || "Não informada"; // Valor padrão para cor
            const size = produto.tamanhoDoProduto
                ? produto.tamanhoDoProduto.map((t) => t.tamanho).join(", ")
                : "Único"; // Junta tamanhos com vírgula ou usa valor padrão

            const row = [
                produto.id,
                `"${produto.nome}"`, // Colocar aspas para lidar com vírgulas em strings
                `"${description}"`,
                `https://www.njresportes.com/produto/${produto.slug}`,
                produto.imagemPrincipal?.url || "",
                `${produto.valor} BRL`,
                produto._status === "published" ? "in stock" : "out of stock",
                "NJR ESPORTES", // Substitua pelo nome da sua marca
                "new",
                "Sporting Goods > Sports Apparel", // Ajuste conforme sua categoria
                `"${color}"`,
                `"${size}"`
            ];
            csvRows.push(row.join(',')); // Adiciona linha ao CSV
        });

        // Cria o arquivo CSV
        const csvContent = csvRows.join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        // Cria link para download
        const a = document.createElement('a');
        a.href = url;
        a.download = 'google-products.csv';
        document.body.appendChild(a);
        a.click();
        a.remove();

        console.log("Arquivo CSV gerado com sucesso!");
    } catch (error) {
        console.error("Erro ao gerar feed CSV:", error.message);
    }
};

