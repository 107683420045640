import React, { useEffect, useState } from "react";
import { Container } from "./style";
import { useNavigate } from "react-router-dom";

const Success = () => {
    const navigate = useNavigate();
    const [isProcessed, setIsProcessed] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const processarCompra = async () => {
            const query = new URLSearchParams(window.location.search);
            const paymentId = query.get("payment_id");
            const externalReference = query.get("external_reference");

            if (!paymentId || !externalReference) {
                setError("Erro: Dados insuficientes.");
                return;
            }

            const pedidoRegistrado = sessionStorage.getItem(`pedido_${paymentId}`);
            if (pedidoRegistrado) {
                return;
            }

            sessionStorage.setItem(`pedido_${paymentId}`, "true");

            let externalData;
            try {
                externalData = JSON.parse(decodeURIComponent(externalReference));
            } catch (error) {
                setError("Erro ao processar o pedido.");
                return;
            }

            if (!externalData || !externalData.pedidoId) {
                setError("Erro ao processar os dados do pedido.");
                return;
            }

            try {
                const response = await fetch(
                    `http://localhost:9000/success-mp?payment_id=${paymentId}&external_reference=${encodeURIComponent(externalReference)}`
                );
                const data = await response.json();

                if (response.status === 200) {
                    const produtos = data.pedido?.produtos || [];

                    // Atualiza o localStorage com os pedidos do usuário
                    const usuario = JSON.parse(localStorage.getItem("u"));
                    if (usuario) {
                        if (!Array.isArray(usuario.pedidos)) {
                            usuario.pedidos = [];
                        }

                        const novoPedido = {
                            _id: paymentId,
                            produtos,
                            data: new Date().toISOString(),
                            status: "Pago",
                        };

                        usuario.pedidos.push(novoPedido);
                        localStorage.setItem("u", JSON.stringify(usuario));
                    }

                    setIsProcessed(true);
                } else {
                    console.error("Erro ao processar o pagamento:", data);
                    setError(data.msg || "Erro ao processar o pagamento.");
                }
            } catch (error) {
                console.error("Erro na requisição ao servidor:", error);
                setError("Erro ao comunicar com o servidor.");
            }
        };

        processarCompra();
    }, [navigate]);

    if (error) {
        return <p>{error}</p>;
    }

    if (!isProcessed) {
        return <p>Carregando...</p>;
    }

    return (
        <Container>
            <h2>Compra realizada com sucesso!</h2>
            <p>Obrigado por comprar na NJR ESPORTES! Acompanhe o seu pedido pelo E-mail.</p>
        </Container>
    );
};

export default Success;

























// import React, { useEffect, useState } from "react";
// import { Container } from "./style";
// import { useNavigate } from "react-router-dom";

// const Success = () => {
//     const navigate = useNavigate();
//     const [isProcessed, setIsProcessed] = useState(false); // Controla a exibição da página
//     const [error, setError] = useState(null); // Controla erros

//     useEffect(() => {
//         const processarCompra = () => {

//             const query = new URLSearchParams(window.location.search);
//             const token = query.get("token"); // Recupera o token da URL
//             const emailCompra = query.get("email");

//             if (!token) {
//                 console.error("Token de compra não encontrado.");
//                 setError("Erro: Pedido inválido.");
//                 return;
//             }

//             const pedidoRegistrado = sessionStorage.getItem(`pedido_${token}`);
//             if (pedidoRegistrado) {
//                 return;
//             }

//             // Marca o token como processado no sessionStorage
//             sessionStorage.setItem(`pedido_${token}`, "true");

//             const produtos = [];
//             query.forEach((value, key) => {
//                 if (key.startsWith("produto")) {
//                     const index = key.replace("produto", "");
//                     const produto = {
//                         nome: query.get(`produto${index}`),
//                         quantidade: parseInt(query.get(`quantidade${index}`), 10),
//                         preco: parseFloat(query.get(`preco${index}`)),
//                     };
//                     produtos.push(produto);
//                 }
//             });

//             let usuario = JSON.parse(localStorage.getItem("u"));

//             if (usuario && usuario.email === emailCompra) {
//                 if (!Array.isArray(usuario.pedidos)) {
//                     usuario.pedidos = []; // Inicializa o array de pedidos
//                 }

//                 const novoPedido = {
//                     id: token, // Associa o token como ID do pedido
//                     produtos,
//                     data: new Date().toISOString(),
//                     status: "Pago",
//                 };
//                 usuario.pedidos.push(novoPedido);


//                 // Atualiza o localStorage com o novo estado do usuário
//                 localStorage.setItem("u", JSON.stringify(usuario));

//                 setIsProcessed(true); // Marca como processado para exibir a página de sucesso
//             } else {
//                 const mensagemErro = "Usuário não encontrado ou email da compra não corresponde.";
//                 console.error(mensagemErro);
//                 setError(mensagemErro);
//             }
//         };

//         processarCompra(); // Executa a lógica ao carregar a página
//     }, [navigate]);

//     if (error) {
//         return <p>{error}</p>; // Exibe a mensagem de erro, se houver
//     }

//     if (!isProcessed) {
//         return <p>Carregando...</p>; // Exibe enquanto processa a compra
//     }

//     return (
//         <Container>
//             <h2>Compra realizada com sucesso!</h2>
//             <p>Obrigado por comprar na NJR ESPORTES! Acompanhe o seu pedido pelo E-mail.</p>
//         </Container>
//     );
// };

// export default Success;
