import React,{useState} from 'react';
import { Container, Content } from './style';
import { Link, useNavigate } from 'react-router-dom';
import { BtnSecondary } from '../../styles/Buttons';
import { api } from '../../hooks/useApi';

const Cadastro = () =>{

    const [showPass,setShowPass] = useState(false);
    const [showPassConfirm,setShowPassConfirm] = useState(false);
    const [values,setValues] = useState('');
    const [localMsg, setLocalMsg] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()


    const handleShowPass = () =>{
        setShowPass(prev => !prev);
    }

    const handleShowPassConfirm = () =>{
        setShowPassConfirm(prev => !prev);
    }

    const handleChangeValues = (value) =>{
        setValues((prev) => ({
            ...prev,
            [value.target.name]: value.target.value,
        }))
    }

    const handleClickButton = (e) => {
        e.preventDefault();
        setLoading(true);
        setLocalMsg(''); // Limpa mensagens de erro anteriores, se houver
    
        api.post('/auth/criar-conta', {
            nome: values.nome,
            email: values.email,
            cpf: values.cpf,
            telefone: values.telefone,
            password: values.password,
            confirmPassword: values.confirmPassword,
            endereco: {
                rua: values.rua,
                bairro: values.bairro,
                numero: values.numero,
                complemento: values.complemento,
                cidade: values.cidade,
                estado: values.estado,
                cep: values.cep,
            }
        }).then((response) => {
            setLoading(false);
            alert("Conta criada com sucesso!");
            navigate('/Login');
        }).catch((error) => {
            setLoading(false); // Certifique-se de parar o loading
            setLocalMsg(error.response?.data?.msg || "Ocorreu um erro ao tentar criar sua conta.");
        });
    }
    

    return(
        <Container>
            <div className='nav_criar__title'>
                <p><Link to='/'>Início</Link> / Cadastre-se</p>
                <h2>CADASTRE-SE</h2>
                <p style={{color: "#555", padding: '10px 0 15px 0', fontSize: '14px'}}>Compre mais rápido e acompanhe seus pedidos em um só lugar!</p>
            </div>

            <Content>
                <div className='form_cadastro'>
                    <form>
                        <div className='input_style'>
                            <p>Nome Completo</p>
                            <input type='text' name='nome' onChange={handleChangeValues} placeholder='ex.: Maria Perez' required/>
                        </div>
                        <div className='input_style'>
                            <p>Email</p>
                            <input type='email' name='email' onChange={handleChangeValues} placeholder='ex.: seunome@email.com.br' required/>
                        </div>
                        <div className='input_style'>
                            <p>CPF</p>
                            <input type='cpf' name='cpf' onChange={handleChangeValues} placeholder='ex.: 111.111.111-11' required/>
                        </div>
                        <div className='input_style'>
                            <p>TELEFONE (OPCIONAL)</p>
                            <input type='text' name='telefone' onChange={handleChangeValues} placeholder='ex.: 88971923030'/>
                        </div>
                        <div className='input_style'>
                            <p>Rua</p>
                            <input type='text' name='rua' onChange={handleChangeValues} placeholder='ex.: Rua das Flores' required/>
                        </div>
                        <div className='input_style'>
                            <p>Bairro</p>
                            <input type='text' name='bairro' onChange={handleChangeValues} placeholder='ex.: Centro' required/>
                        </div>
                        <div className='input_style'>
                            <p>Número</p>
                            <input type='text' name='numero' onChange={handleChangeValues} placeholder='ex.: 123' required/>
                        </div>
                        <div className='input_style'>
                            <p>Complemento (OPCIONAL)</p>
                            <input type='text' name='complemento' onChange={handleChangeValues} placeholder='ex.: Apt 101'/>
                        </div>
                        <div className='input_style'>
                            <p>Cidade</p>
                            <input type='text' name='cidade' onChange={handleChangeValues} placeholder='ex.: São Paulo' required/>
                        </div>
                        <div className='input_style'>
                            <p>Estado</p>
                            <input type='text' name='estado' onChange={handleChangeValues} placeholder='ex.: SP' required/>
                        </div>
                        <div className='input_style'>
                            <p>CEP</p>
                            <input type='text' name='cep' onChange={handleChangeValues} placeholder='ex.: 01001-000' required/>
                        </div>
                        <div className='input_style'>
                            <p>senha</p>
                            <p className='ver_senha' onClick={handleShowPass}>{!showPass ? 'Mostrar' : 'esconder'}</p>
                            <input type={!showPass ? 'password' : ''} name='password' onChange={handleChangeValues} autoComplete='off' required/>
                        </div>
                        <div className='input_style'>
                            <p>confirmar senha</p>
                            <p className='ver_senha' onClick={handleShowPassConfirm}>{!showPassConfirm ? 'Mostrar' : 'esconder'}</p>
                            <input type={!showPassConfirm ? 'password' : ''} name='confirmPassword' onChange={handleChangeValues} autoComplete='off' required/>
                        </div>
                        <div className='btn_entrar'>
                        <BtnSecondary 
                            secondary
                            upper 
                            pad 
                            onClick={handleClickButton} 
                            disabled={loading} // Desativa o botão durante o loading
                            style={{ 
                                backgroundColor: loading ? '#68C9A9' : '' // Define a cor de fundo "secondary" durante o loading
                            }}                    
                        >
                            {loading ? (
                                <svg width="20" height="20" viewBox="0 0 300 150"><path fill="none" stroke="#FFFFFF" stroke-width="30" stroke-linecap="round" stroke-dasharray="300 385" stroke-dashoffset="0" d="M275 75c0 31-27 50-50 50-58 0-92-100-150-100-28 0-50 22-50 50s23 50 50 50c58 0 92-100 150-100 24 0 50 19 50 50Z"><animate attributeName="stroke-dashoffset" calcMode="spline" dur="2" values="685;-685" keySplines="0 0 1 1" repeatCount="indefinite"></animate></path></svg>
                            ) : "Cadastre-se"}
                        </BtnSecondary>
                        </div>
                        {localMsg && <div className="error_message">{localMsg}</div>}
                    </form>
                    <div className='iniciar_sessao'>
                        <p>já possui uma conta? <Link to='/login'>Iniciar sessão</Link></p>
                    </div>
                </div>
            </Content>
        </Container>
    );
}


export default Cadastro;
